import React, { useEffect } from 'react';
import WebinarPage from '../components/webinar-page';
import '../styles/webinar-page.css';
///////////////////////////////////////////////////////////////////////////////////////////////////////////
const WebinarUnderstandingPaterns = () => {
  //
  const webinarUrl = "https://webinarkit.com/webinar/registration/oneclick/67bc2e4ad0dd86508e900f5f?date=jit_15";
  const title = "From Idea to Patent: The Inventor's Journey Got Even More Dangerous in 2025";
  const canonical = "/webinar-understanding-patents-from-idea-to-invention.";
  const introAuthor = "Bao Tran is a foremost patent expert with over 15 years in IP law."
  const lessons = [
    "The process of transforming an idea into a patentable invention.",
    "Key patent filing strategies for new inventors.",
    "How to deal with patent rejections and objections.",
    "The impact of patents on business growth and innovation."
  ]
  const btnTxt = "Watch Webinar Now";
  useEffect(() => {
    document?.body.classList.add('no-home');
    //
  }, []);

  return (
    <>
    <WebinarPage webinarUrl={webinarUrl} introAuthor={introAuthor} title={title} canonical={canonical} lessons={lessons} btnTxt={btnTxt}></WebinarPage>
    </>
  )
};

export default WebinarUnderstandingPaterns;